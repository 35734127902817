// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //apiURL: 'http://localhost:4000',
  //previewURL: 'https://mapa.run/stationCard/#/preview?stationId=',
  //apiURL: 'https://cardbuilderapi.mapa.run',
  //previewURL: 'https://mapa.run/stationCard/#/preview?stationId=',
  apiURL: 'https://cardbuilderapi.beta.experien.city',
  previewURL: 'https://beta.experien.city/stationCard/#/preview?stationId=',
  //apiURL: 'https://cardbuilderapi.uat.experien.city',
  //previewURL: 'https://uat.experien.city/stationCard/#/preview?stationId=',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.